
//redirect to defaault browser from in app browser
const getUA = () => {
    if ('undefined' !== typeof window) {
        const ua =
            window?.navigator?.userAgent ||
            window?.navigator?.vendor ||
            window?.opera;
        if (ua) return ua;
    }
    return '';
};
const getIsInApp = (ua) => {
    return null !== ua.match(new RegExp(
        `${[
            'WebView',
            '(iPhone|iPod|iPad)(?!.*Safari/)',
            'Android.*wv\\)',
            'FB_\\w|FB\\w',
            'Snapchat',
            'GSA',
        ].map((reg) => `(${reg})`).join('|')}`,
        'ig'
    ));
};
const ua = getUA()
if (getIsInApp(ua)) {
    const url = window.location.href;
    window.location.replace(null !== ua.match(/(iPhone|iPad|iPod|Macintosh)/) ? `x-safari-${url}` : `intent:${url}#Intent;end`);
}